import React from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';

const Under = () => {
   return (
    <>
      <Helmet>
        <title>DIP | Home</title>
      </Helmet>
      <Header />
        <section className="py-0 px-3" id="home">
        <div className="container">
            <div className="row align-items-center min-vh-100 min-vh-md-100 vh-sm-100 vh-100">
                <div className="col-sm-6 col-md-6 col-lg-6 text-sm-start text-center">
                    <h1 className="display-2 fw-semi-bold lh-sm fs-4 fs-lg-6 fs-xxl-8">Under<strong> construction </strong></h1>
                   <p className="fs-1 mb-5">Page is under construction <br />
                       Coming soon</p>
                    </div>
                 <div className="col-sm-6 col-md-6 col-lg-6 text-sm-start text-center">
                   <img src="/assets/images/net2.png" alt="Database" className="img-fluid"/>
                 </div>
            </div>
        </div>
    </section>
      <Footer/>
    </>
   )
}

export default Under