import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Network from '../../components/Network/NetworkDiagram';
import Legend from '../../components/Legend/Legend';
import Distogram from '../../components/Distogram/Distogram';
import { Helmet } from 'react-helmet';
import 'react-modern-drawer/dist/index.css';
import Swal from 'sweetalert2';
import 'animate.css';
import 'primeicons/primeicons.css';

import MolstarViewer from '../../components/MolstarViewer/MolstarViewer';
import { Link } from 'react-router-dom';
import InteractomeTable from '../../components/InteractomeTable/InteractomeTable';
import { ProgressSpinner } from 'primereact/progressspinner';


import {downloadSvg, downloadSvgAsPNG} from '../../download.js';
import {handleClickInfo, handleClickHelp} from '../../modal.js';
import {fetchData} from '../../data.js';

const ExplorePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.state) {
      navigate('/');
    }
  }, [location.state, navigate]);

  // Get values
  const state = location.state || [];
  const initialData = state.data || [];
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState({ nodes: [], links: [] });
  const [pdbId, setPdbId] = useState();
  const [annotation, setAnnotation] = useState("Domains");

  const [gene, setGene] = useState("");
  const [prob, setProb] = useState(0);
  const [maxProb, setMaxProb] = useState(1);
  const [domains, setDomains] = useState([]);
  const [parsedData, setParsedData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [loadingDistogram, setLoadingDistogram] = useState(false);

  const svgRef = useRef();

  const geneMap = require('../../UniAC_GENE.json');

  useEffect(() => {

  // Filter links with interaction type "dip"
  const filteredLinksDip = initialData.links
    .filter(link => link.interaction === "dip")
    .sort((a, b) => {
      // First, sort by target alphabetically
      const targetComparison = a.target.localeCompare(b.target);

      // If targets are the same, sort by probability in descending order
      if (targetComparison === 0) {
        return b.probability - a.probability; // Sort probability in descending order
      }

      return targetComparison; // Otherwise, return the result of target comparison
    });

  setFilteredData(filteredLinksDip);
  }, [initialData]);


// Set probability value
const handleProbInput = (e) => {
  setProb(Number(e.target.value));
};

// Filter network data
const filterData = () => {

  var filteredNodes = initialData.nodes.filter(node => node.id === gene || node.id === "LRRK2");

  var filteredLinks = initialData.links.filter(link => {
    const sourceNodeExists = filteredNodes.some(node => node.id === link.source);
    const targetNodeExists = filteredNodes.some(node => node.id === link.target);

    return sourceNodeExists && targetNodeExists && link.interaction === "dip" && link.score >= prob;
  });

  const maxScoreDip = filteredLinks.reduce((maxScore, link) => {
    return Math.max(maxScore, link.score);
  }, -Infinity);

  setMaxProb(maxScoreDip);

  var filteredLinksGeneBinding = initialData.links.filter(link => {
    const sourceNode = filteredNodes.find(node => node.id === link.source);
    const targetNode = filteredNodes.find(node => node.id === link.target);

    return sourceNode && targetNode && link.interaction === "gene" && link.score >= 0;
  });

  var allFilteredLinks = filteredLinksGeneBinding.concat(filteredLinks);

  const filteredDataRow = {
    links: allFilteredLinks,
    nodes: filteredNodes
  };

  var filteredDomains = initialData.nodes.filter(node => node.id === gene);

  setPdbId(findKeyByValue(gene));
  setDomains(filteredDomains[0].domains);
  setSelectedData(filteredDataRow);
  setAnnotation("Domains");
  fetchData(findKeyByValue(gene), setParsedData, setLoadingDistogram);
  setLoading(false);

};

const findKeyByValue = (value) => {
    const key = Object.keys(geneMap).find(key => geneMap[key] === value);
    return key || '';
};

// Load network based on filter
useEffect(() => {
  if (gene !== "" && prob !== undefined) {
    filterData();
  }
}, [gene, prob, initialData]);

// Set annotation
const handleAnnotation = (e) => {
    setAnnotation(e.target.value);
};


// Export SVG
const handleExportSVG = () => {
    downloadSvg(svgRef);
};

// Export SVG
const handleExportPNG = () => {
    downloadSvgAsPNG(svgRef);
};

  return (
    <>
     <Helmet>
      <title>DIP | Explore</title>
    </Helmet>
    <Header />
    <section className="py-0 my-1">
      <div className="container px-3 mt-8">
        <div className="row mb-4">
          <div className="col-12 d-flex align-items-center">
            <h2 className="display-6 fw-bold mb-4">
              Explore
              <span className="badge" onClick={handleClickInfo}>
                <button type="button" className="btn btn-info">
                  <ion-icon name="information-outline"></ion-icon>
                </button>
              </span>
            </h2>
          </div>
        </div>
        <div className="row mb-5">
          <InteractomeTable
            interactomeData={filteredData}
            setLoading={setLoading}
            setGene={setGene}
            setProb={setProb}
          />
        </div>

        {loading && (
          <div className="row mb-4">
            <ProgressSpinner
              style={{ width: '50px', height: '50px' }}
              strokeWidth="8"
              fill="var(--surface-ground)"
              animationDuration=".5s"
            />
          </div>
        )}

        {(!loading && selectedData.nodes.length > 0) && (
          <>
            {/* Network Section */}
            <div className="row ">
              <h2 className="display-6 fw-bold mb-5">Network</h2>
              <div className="col-lg-3 col-md-3 col-sm-3">
                <div className="bg-light rounded-3 px-4 py-3">
                  <h6 className="fw-bold">Viewer Tools</h6>
                  <div className="mb-3 g-3">
                    <button
                      type="button"
                      className="btn button-net mb-2"
                      onClick={handleClickHelp}
                    >
                      <span className="button-net__text">Help</span>
                      <span className="button-net__icon">
                        <ion-icon name="help-circle-outline"></ion-icon>
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn button-net mb-2"
                      onClick={handleExportSVG}
                    >
                      <span className="button-net__text">Export as SVG</span>
                      <span className="button-net__icon">
                        <ion-icon name="download-outline"></ion-icon>
                      </span>
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm button-net mb-2"
                      onClick={handleExportPNG}
                    >
                      <span className="button-net__text">Export as PNG</span>
                      <span className="button-net__icon">
                        <ion-icon name="camera-outline"></ion-icon>
                      </span>
                    </button>
                    <h6 className="fw-bold">Network Tools</h6>
                    <div className="mb-1 d-flex align-items-center">
                      <label htmlFor="annotation" className="form-label me-3">Probability</label>
                      <input
                        type="range"
                        className="form-range w-50 me-3"
                        min="0"
                        max={maxProb}
                        step="0.1"
                        value={prob}
                        onChange={handleProbInput}
                      />
                      <span>{prob}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6">
                <Network data={selectedData} svgRef={svgRef} />
              </div>
              <div className="col-sm-3 col-md-3 col-lg-3">
                <Legend proteins={selectedData} />
              </div>
            </div>

            {/* Structure Section */}
            <div className="row mb-5">
              <h2 className="display-6 fw-bold mb-5">Structure</h2>
              <div className="col-md-2 mb-2">
                <div className="bg-light rounded-3">
                    <div className="px-4 py-4">
                        <div className="mb-2">
                            <h6 className="fw-bold mb-3">Viewer Tools</h6>
                            <label
                                    htmlFor="structure-color"
                                    className="form-label"
                            >
                                Annotation&nbsp;
                            </label>
                            <select
                                    className="form-select mb-2 outline-none"
                                    name="structure-color"
                                    value={annotation}
                                    onChange={handleAnnotation}
                            >
                                <option value="None">None</option>
                                <option value="Domains">Domains</option>
                                <option value="pLDDT">pLDDT</option>
                                <option value="Surface">Surface</option>
                            </select>
                            <p className="fs--2 text-justify">
                                Click on the viewer plugin icons to access more
                                advanced features from
                                <Link target="_blank" to="https://molstar.org/viewer-docs/">
                                <b> Mol*</b>
                                </Link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
                <div className="viewer-box">
                    <MolstarViewer
                            pdbId={pdbId}
                            database="AF"
                            domains={domains}
                            annotation={annotation}
                    />
                </div>
            </div>
            </div>
             {loadingDistogram && (
              <div className="row mb-11">
              <h2 className="display-6 fw-bold mb-4">Distogram</h2>
               <div className="col-md-12">
                     Your results will be available in a few seconds...
                </div>
              </div>
            )}
            {/* Distogram Section */}
            { (!loadingDistogram &&  parsedData) && (
            <div className="row mb-11">
              <h2 className="display-6 fw-bold mb-4">Distogram</h2>
              <div className="col-md-6">
                 <Distogram distogramData={parsedData}  interactor={gene}/>
              </div>
              </div>
            )}

            {(!loadingDistogram  && !parsedData)&& (
              <div className="row mb-11">
              <h2 className="display-6 fw-bold mb-4">Distogram</h2>
               <div className="col-md-12">
                     No results found
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
    <Footer />

    </>
  );
};

export default ExplorePage;
