import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

const Distogram = ({ distogramData, interactor }) => {

  const [data, setData] = useState([]);

  useEffect(() => {

         setData( [{
                z: distogramData,
                type: 'heatmap',
                colorscale: 'Viridis',
                hoverongaps: false
              }]);

   }, [distogramData]);

   const layout = {
		yaxis: {title:'LRRK2'},
        xaxis: {title: interactor},
        displaylogo: false
   };

  return (
    <Plot
      data={data}
      useResizeHandler={true}
      style={{ width: '100%', height: '100%' }}
      layout={layout}
    />
  );
};

export default Distogram;
