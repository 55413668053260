import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const generateLegendData = () => [
  {
    type: 'protein',
    color: 'none',
    custom: true, // Add a custom flag for this item
  },
  { label: 'Cross-link (Protein-protein level)', type: 'cross-link-protein', color: '#cbd5e8' },
  { label: 'Cross-link (Domain-domain level)', type: 'cross-link-domain', color: '#b3e2cd' },
  { label: 'Binding region (IntAct)', type: 'experimental-evidence', color: '#fdcdac' },
];

const Legend = ({ proteins }) => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svg = d3.select(svgRef.current)
    const legendData = generateLegendData();

    const legendItemSize = 10;
    const legendSpacing = 4;
    const xOffset = 0;
    let yOffset = 2;

    svg.selectAll(".legend").remove();

    const additionalLegend = svg.append('g')
      .attr("class", 'legend additional-legend')
      .selectAll('.additionalLegendItem')
      .data(legendData)
      .enter();

    additionalLegend.append('rect')
      .attr('class', 'additionalLegendItem')
      .attr('width', legendItemSize)
      .attr('height', legendItemSize)
      .style('fill', d => d.color)
      .attr('transform',
        (d, i) => `translate(${xOffset}, ${yOffset + (legendItemSize + legendSpacing) * i})`);

    additionalLegend.append('text')
      .style("font-size", "0.75rem")
      .style('font-family', 'inherit')
      .attr("class", "additionalLegendItemLabel")
      .attr('x', xOffset + legendItemSize + 5)
      .attr('y', (d, i) => yOffset + (legendItemSize + legendSpacing) * i + 10)
      .text(d => d.label);

    // Draw the custom "Protein: Click to switch" item
    const customLegend = svg.append('g')
      .attr("class", 'legend protein-0')
      .selectAll('.legendItem')
      .data(legendData.filter(item => item.custom))
      .enter();

    customLegend.append('rect')
      .attr('class', 'legendItem')
      .attr('width', 40) // Adjust the width
      .attr('height', legendItemSize)
      .style('fill', 'none')
      .style('stroke', '#000000')
      .style('stroke-width', 1)
      .attr('transform', `translate(${xOffset}, ${yOffset})`);

    // Domains inside the react
	customLegend.append('rect')
              .attr('width', 10)
              .attr('height', legendItemSize - 2.5)
              .style('stroke', '#c4f888')
              .style('fill', '#c4f888')
              .attr('transform', `translate(${xOffset + 2}, ${yOffset + 1.25})`);

    customLegend.append('rect')
              .attr("class", "customLegend")
              .attr('width', 15)
              .attr('height', legendItemSize - 2.5)
              .style('stroke', '#f9f871')
              .style('fill', '#f9f871')
              .attr('transform', `translate(${xOffset + 20}, ${yOffset + 1.25 })`);


    customLegend.append('text')
      .style("font-size", "0.75rem")
      .style('font-family', 'Arial')
      .attr("class", "customLegendLabel")
      .attr('x', xOffset + 45) // Adjust the x-coordinate
      .attr('y', yOffset + 8)
      .text('Protein: Double click to switch');

    // Update the yOffset for the domain-based legend items
    yOffset += (legendItemSize + legendSpacing) + 45;

    const uniqueDomains = {};

    if (proteins && proteins.nodes.length > 0) {
      proteins.nodes.forEach(node => {
        node.domains.forEach(domain => {
          if (!uniqueDomains[domain.name]) {
            uniqueDomains[domain.name] = domain.color;
          }
        });
      });

      const domainData = Object.entries(uniqueDomains).map(([name, color], i) => ({ name, color }));

      const legend = svg.append('g')
        .attr("class", 'legend')
        .selectAll('.legendItem')
        .data(domainData)
        .enter();

      legend.append('rect')
        .attr('class', 'legendItem')
        .attr('width', legendItemSize)
        .attr('height', legendItemSize)
        .style('fill', d => "#" + d.color)
        .attr('transform',
          (d, i) => `translate(${xOffset}, ${yOffset + (legendItemSize + legendSpacing) * i})`);

      legend.append('text')
        .style("font-size", "0.75rem")
        .style('font-family', 'inherit')
        .attr("class", "protein xlv_text proteinLabel")
        .attr('x', xOffset + legendItemSize + 5)
        .attr('y', (d, i) => yOffset + (legendItemSize + legendSpacing) * i + 10)
        .text(d => d.name);
    }
  }, [proteins]);

  return <svg ref={svgRef} width="100%" height="100%" viewBox={`0 0 400 800`} preserveAspectRatio="xMidYMid meet"></svg>;
};

export default Legend;
