import React, { useEffect, useRef, useMemo } from 'react';
import { createPluginUI } from 'molstar/lib/mol-plugin-ui';
import { ColorNames } from 'molstar/lib/mol-util/color/names';
import 'molstar/lib/mol-plugin-ui/skin/light.scss';
import { renderReact18 } from 'molstar/lib/mol-plugin-ui/react18';
import { PluginCommands } from 'molstar/lib/mol-plugin/commands';
import { Script } from 'molstar/lib/mol-script/script';
import { StructureSelection } from 'molstar/lib/mol-model/structure/query';
import { MolScriptBuilder as MS, MolScriptBuilder } from 'molstar/lib/mol-script/language/builder';
import { StateTransforms } from 'molstar/lib/mol-plugin-state/transforms';
import { createStructureRepresentationParams } from 'molstar/lib/mol-plugin-state/helpers/structure-representation-params';
import { Color } from "molstar/lib/mol-util/color";
import { Structure, StructureElement, StructureProperties } from 'molstar/lib/mol-model/structure';

const MolstarViewer = ({ pdbId, annotation, domains }) => {

  const viewerRef = useRef(null);
  const pluginInstanceRef = useRef(null);

  const initMolstar = useMemo(() => async () => {
    if (viewerRef.current && !pluginInstanceRef.current) {
      const plugin = await createPluginUI({
        target: viewerRef.current,
        layoutIsExpanded: false,
        layoutShowControls: false,
        layoutShowRemoteState: false,
        layoutShowSequence: true,
        layoutShowLog: false,
        layoutShowLeftPanel: false,
        viewportShowExpand: false,
        viewportShowSelectionMode: false,
        viewportShowAnimation: false,
        backgroundColor: 'white',
        render: renderReact18,
      });
      pluginInstanceRef.current = plugin;

      try {

       // Set structure database
       const urlStructure = `https://api.dip.bioinfolab.sns.it/api/cif/${pdbId}`;

      const data = await pluginInstanceRef.current.builders.data.download(
          { url: urlStructure },
          { state: { isGhost: true } }
        );

        const trajectory = await pluginInstanceRef.current.builders.structure.parseTrajectory(data, 'mmcif');
        const structure = await pluginInstanceRef.current.builders.structure.hierarchy.applyPreset(
          trajectory,
          'default'
          , {
            showUnitcell: false,
            representationPreset: 'auto',
        }
        );

        // Change background color
        const renderer = plugin.canvas3d.props.renderer;
        PluginCommands.Canvas3D.SetSettings(plugin, { settings: { renderer: { ...renderer, backgroundColor: ColorNames.white } } });

        const dta = plugin.managers.structure.hierarchy.current.structures[0]?.cell.obj?.data;
        if (!dta) return;

        // Get polymer representation
        const cartoon = structure.representation.representations.polymer;

        const domains_lrrk2 = [{"name": "Armadillo-type fold", "start": "35", "end": "628", "color": "f078e5"},
            {"name": "Ankyrin repeat-containing domain superfamily", "start": "652", "end": "803", "color": "da54a3"},
            {"name": "Leucine-rich repeat domain superfamily", "start": "941", "end": "1308", "color": "838ffc"},
            {"name": "Roc domain", "start": "1328", "end": "1511", "color": "820e44"},
            {"name": "C-terminal of Roc (COR) domain", "start": "1545", "end": "1740", "color": "c746d4"},
            {"name": "Protein kinase domain", "start": "1879", "end": "2132", "color": "500f27"},
            {"name": "WD40/YVTN repeat-like-containing domain superfamily", "start": "2155", "end": "2522", "color": "310b8a"}]


        if (annotation === "Domains"){

             // Create and apply custom representation
            const reprParamsStructureResetColor = createStructureRepresentationParams(plugin, undefined, {
              type: 'cartoon',
              color: 'uniform',
              colorParams: { value: ColorNames.white }
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureResetColor);

            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

            // Add layers to params object
            for (let i = 0; i < domains.length; i++) {
                let start = parseInt(domains[i].start);
                let stop = parseInt(domains[i].end);
                let color = domains[i].color;
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id ${pdbId} )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            for (let i = 0; i < domains_lrrk2.length; i++) {
                let start = parseInt(domains_lrrk2[i].start);
                let stop = parseInt(domains_lrrk2[i].end);
                let color = domains_lrrk2[i].color;
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id Q5S007 )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        } else if (annotation === "pLDDT"){

           const MyProvider = {
              label: (loci) => {
                if (StructureElement.Loci.is(loci)) {
                  const loc = StructureElement.Loci.getFirstLocation(loci);
                  if (!loc) return;
                  const bFactor = StructureProperties.atom.B_iso_or_equiv(loc);
                  console.log(loc)
                  return `pLDDT: ${bFactor}`;
                }
                return;
              },
             };

             // Create and apply custom representation
            const reprParamsStructurePlddtColor = createStructureRepresentationParams(plugin, undefined, {
                type: "cartoon",
                color: "uncertainty",
                colorParams: {
                  value: 1,
                  domain: [0, 100],
                  list: {
                    colors: [
                      Color(0x0053d6),
                      Color(0x65cbf3),
                      Color(0xffdb13),
                      Color(0xff7d45),
                      Color(0xffffff)
                    ],
                  },
                },
                size: "uniform",
            });

            const updateTheme = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructurePlddtColor);

            plugin.managers.lociLabels.addProvider(MyProvider);

            await updateTheme.commit();

        } else if (annotation === "Surface"){

             // Create and apply custom representation
            const reprParamsStructureGaussian = createStructureRepresentationParams(plugin, undefined, {
               type: "molecular-surface",
               color: 'uniform',
               colorParams: { value: ColorNames.white },
               size: 'physical'
            });

            const update = plugin
            .build()
            .to(cartoon)
            .update(reprParamsStructureGaussian);


            // Set script language
            const language = 'mol-script';

            // Create params object
            const params = {
                layers: []
            };

             // Add layers to params object
            for (let i = 0; i < domains.length; i++) {
                let start = parseInt(domains[i].start);
                let stop = parseInt(domains[i].end);
                let color = domains[i].color;
                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id ${pdbId} )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }

            for (let i = 0; i < domains_lrrk2.length; i++) {
                let start = parseInt(domains_lrrk2[i].start);
                let stop = parseInt(domains_lrrk2[i].end);
                let color = domains_lrrk2[i].color;

                params.layers.push({
                    script: Script(
                          `(sel.atom.atom-groups
                             :chain-test (= atom.auth_asym_id Q5S007 )
                             :residue-test (in-range atom.resno ${start} ${stop} )
                          )`,
                        language
                    ),
                    color: Color("0x" + color),
                    clear: false
                });
            }
           update
                  .to(cartoon)
                  .apply(StateTransforms.Representation.OverpaintStructureRepresentation3DFromScript, params);

            await update.commit();

        }

      } catch (error) {
        console.error('Failed to load the PDB structure:', error);
      }
    }
  }, [pdbId, annotation, domains]);

  useEffect(() => {
    initMolstar();

    return () => {
      if (pluginInstanceRef.current) {
        pluginInstanceRef.current.dispose();
        pluginInstanceRef.current = null;
      }
    };
  }, [initMolstar]);

  return <div key={pdbId} ref={viewerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MolstarViewer;
