import React, { useState, useEffect, useRef } from 'react';
import Swal from 'sweetalert2';



// Handle Info modal
export const handleClickInfo = (event) => {
    Swal.fire({
      html: `
          <div className="container my-2">
            <h2 className="display-6 fw-bold mb-2">Domain Interaction Predictor</h2>
            <p className="text-justify fs-1">This tool allows exploring domain-domain interactions
            between LRRK2 and its interactors annotated in IntAct.
            These predictions have been made using DIP (Domain Interaction Predictor),
            a computational method developed by our group to predict domain-domain
            interactions using a classifier based on XGBoost. For this task,
            the predictor combines coevolution calculated with mirrortree,
            mutual information between the presence of domains across the tree of life,
            and common GO terms.
            </p>
          </div>
        `,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    });
  };



// Handle Help modal
export const handleClickHelp = (event) => {
    Swal.fire({
      html: `
          <div className="container my-2">
            <h2 className="display-6 fw-bold text-center">Help</h2>
            <p className="text-justify fs-1">The network viewer provides a summary of LRRK2 interactions through
            a visual representation using a network architecture. Proteins are depicted as circles,
             and interactions are illustrated as colored lines (edges), each corresponding to a
             specific type of interaction. The width of the edges reflects the probability of the respective interaction.
             Hovering the mouse pointer over elements triggers tooltips,
            displaying detailed information about the interaction or interactors next to the corresponding element.
            </p>
          </div>
        `,
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    });
  };