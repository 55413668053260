import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';


// Get distogram data from API
export const fetchData = async (pdbId, setParsedData, setLoadingDistogram) => {

  try {
    setLoadingDistogram(true); // Show loader
    setParsedData(null); // Clear previous data

    const apiUrl = 'https://api.dip.bioinfolab.sns.it/api/distogram/';
    const response = await axios.post(apiUrl, { target: pdbId });

    if (response.data.error) {
      setParsedData(null);
    } else {
      setParsedData(response.data);
    }

  } catch (error) {
    console.error('Error loading JSON data:', error);
    setParsedData(null);
  } finally {
    setLoadingDistogram(false); // Hide loader after fetching
  }
};