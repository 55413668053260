import React, { useState, useEffect, useRef } from 'react';
import domtoimage from 'dom-to-image';

 // Download SVG
 export function downloadSvg(svgRef) {
    const svgString = new XMLSerializer().serializeToString(svgRef.current);
    const dataUrl = `data:image/svg+xml;base64,${btoa(svgString)}`;

    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = 'network_dip.svg';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

 // Download PNG
 export function downloadSvgAsPNG(svgRef) {
    const svg = svgRef.current;

    // Use dom-to-image to capture the SVG as a data URL
    domtoimage
      .toPng(svg)
      .then((dataUrl) => {
        // Trigger the download
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = 'network.png';
        a.click();
      })
      .catch((error) => {
        console.error('Error converting SVG to PNG:', error);
      });
  }
