import React, { useState } from 'react';
import {useNavigate} from "react-router-dom"
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';

import Swal from 'sweetalert2'
import { Button } from '@chakra-ui/react'

const HomePage = () => {

// Create variables
const navigate = useNavigate();
const [loading, setIsLoading] = useState(false);

const onSubmitHandler = (e) => {
    e.preventDefault();

    setIsLoading(true);
    setTimeout(sendExploreData, 500);
};


// Get input data
const getResult = async (genes) => {
    try {
        // Read data from dip.json
        const data = require('../../dip.json');

        // Return the result
        return { data: data  }

    } catch (error) {
       setIsLoading(false);
    }
};

// Function to send form data to the server
async function sendExploreData() {
    try {

        const result = await getResult();

        // Check if the response contains the expected data
        if (result && !result.error) {
            if (result.data.links.length > 0) {
                // Navigate to the explore page with the received data
                navigate('/explore/', { state: result });
            }
        } else {
            setIsLoading(false);
            Swal.fire({
                icon: "info",
                text: result.error + ". Please change your search criteria and try again."
            });
        }
    } catch (error) {
        setIsLoading(false);
    }
}


   return (
   <>
      <Helmet>
        <title>DIP | Home</title>
      </Helmet>
      <Header />
        <section className="py-0 px-3" id="home">
        <div className="container">
            <div className="row align-items-center min-vh-100 min-vh-md-100 vh-sm-100 vh-100">
                <div className="col-sm-6 col-md-6 col-lg-6 text-sm-start text-center">
                    <h1 className="display-2 fw-semi-bold lh-sm fs-4 fs-lg-6 fs-xxl-8 mt-6">Explore LRRK2 interactome</h1>
                    <p className="mb-4 fs-1 fs-lg-1 fs-xxl-2">
                       Explorer domain-domain interactions between LRRK2 and its interactors annotated in
                    IntAct. These predictions have been made using DIP (Domain Interaction Predictor), a computational
                    method developed by our group to predict domain-domain interactions using a classifier based on
                    XGBoost.
                    </p>
                    <form className="row g-4 mb-6"  onSubmit={onSubmitHandler} >
                        <div className="col-auto">
                        {!loading && <Button type="submit" className="btn btn-primary mb-1 btn-form">Explore now</Button>}
                        {loading && <Button type="submit" isLoading className="btn btn-primary mb-1 btn-form">Explore now</Button>}
                         </div>
                    </form>
                </div>
                 <div className="col-sm-6 col-md-6 col-lg-6 text-sm-start text-center">
                   <img src="/assets/images/net2.png" alt="Database" className="img-fluid"/>
                 </div>
            </div>
        </div>
    </section>
      <Footer/>
    </>
   )
}

export default HomePage